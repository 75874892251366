export default [
  // 2021
  {
    title: 'Unboxing Cryptography',
    event: 'ReactJS Girls Meetup',
    location: 'Remote',
    date: ['2021-01-14'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/unboxing-cryptography',
      links: null,
      video: 'https://youtu.be/-7SxrMAkRp0?t=1337',
    },
    description:
      'I have been reading about Cryptography recently. This is a motivational talk that tries to give an overview of cryptography (from the basics to some of its use cases).',
  },
  // 2020
  {
    title: 'Fixing accessibility on my website',
    event: 'Ladies of Code Paris Meetup',
    location: 'Remote',
    date: ['2020-07-09'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/accessibility-website',
      links: 'https://github.com/sericaia/broken-aria-form-example',
      video: 'https://www.youtube.com/watch?v=NnluF6paMmI',
    },
    description:
      'Besides explaining how I fixed most accessibility issues in this website, I also talk about how to create accessible forms.',
  },

  // 2019

  {
    title: 'WebSecurity 101',
    event: 'Require-lx Meetup',
    location: 'Lisbon, Portugal',
    date: ['2019-09-12'],
    icons: ['star'],
    media: {
      picture: null,
      slides: '../../websecurity-101.pdf',
      links: null,
      video: 'https://www.youtube.com/watch?v=xhKE6-PSq4E',
    },
    description:
      'Basics and not-so-basics about web security. This talk tries to consolidate these topics and present interesting examples (e.g. using GraphQL). The video is available but unfortunately the recording is not perfect.',
  },

  // 2018

  {
    title: 'Accessibility: A Real Use Case',
    event: 'ReactJS Girls Meetup',
    location: 'London, UK',
    date: ['2018-01-23'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/accessibility-a-real-use-case',
      links: null,
      video: 'https://www.youtube.com/watch?v=1z6JCjfhcbY',
    },
    description:
      'Explained A11Y concepts using UK Gov examples and also talked about day-to-day difficulties of a close friend.',
  },

  {
    title: 'Accessibility in Portugal: A real use case',
    event: 'Braga.js Meetup',
    location: 'Braga, Portugal',
    date: ['2018-01-16'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/accessibility-in-portugal',
      links: null,
      video: null,
    },
    description:
      'Explained A11Y concepts using PT Gov examples and also talked about day-to-day difficulties of a close friend.',
  },

  // 2017

  {
    title: 'The rise of HTTP/2',
    event: 'EmpireConf',
    location: 'NYC, USA',
    date: ['2017-10-12', '2017-10-13'],
    icons: ['star'],
    media: {
      picture: '../../empire-conf.png',
      slides: 'https://slides.com/sericaia/the-rise-of-http-2',
      links: 'https://github.com/sericaia/http2-examples-empireconf',
      video: 'https://www.youtube.com/watch?v=yToHjxhCeYM',
    },
    description:
      'Amazing 2-day conference in NYC, I manage to meet a lot of interesting people. I manage to explain HTTP/2 using the metaphor of an orchestra.',
  },

  {
    title: 'ECMAScript: Understanding how JS evolves',
    event: 'PixelsCamp Conference',
    location: 'Lisbon, Portugal',
    date: ['2017-09-28', '2017-09-30'],
    icons: ['star'],
    media: {
      picture: null,
      slides:
        'https://slides.com/sericaia/ecmascript-understanding-how-js-evolves',
      links: 'https://github.com/sericaia/ecmascript-proposals-pixelscamp',
      video: null,
    },
    description:
      'One of the most interesting talks I did. Unfortunately there is no recording from it. It was about how JavaScript evolves with TC39, what are the organizations behind it and what proposals are being discussed.',
  },

  {
    title: 'React Workshop',
    event: 'JSDayES 2017',
    location: 'Madrid, Spain',
    date: ['2017-05-12', '2017-05-13'],
    icons: [],
    media: {
      picture: null,
      slides: null,
      links: null,
      video: null,
    },
    description: 'React workshop at the conference.',
  },

  {
    title: 'Podcast: Offline First as a Social Movement',
    event: 'Offline Camp (un)Conference',
    location: 'Berlin, Germany',
    date: ['2017-04-28', '2017-05-01'],
    icons: ['podcast'],
    media: {
      picture: null,
      slides: null,
      links:
        'https://hwcdn.libsyn.com/p/5/f/c/5fc8c8ed1971c679/offlineasasocialmovement.mp3?c_id=19263875&cs_id=19263875',
      video: null,
    },
    description:
      'New Builders Podcast invited Daniela Matos de Carvalho of YLD, Myles Borins of Google, and Boaz Sender of Bocoup to talk about the Offline First community and the concept of Offline First as a social movement.',
  },

  {
    title: 'Learnings from HTTP/2 & Service Workers',
    event: 'Offline Camp (un)Conference',
    location: 'Berlin, Germany',
    date: ['2017-04-28', '2017-05-01'],
    icons: [],
    media: {
      picture: null,
      slides: null,
      links: null,
      video: 'https://www.youtube.com/watch?v=s6ngNWk9goo',
    },
    description: 'Passion talk in a unconference style!',
  },

  {
    title: 'Webinar: Step into the world of microservices using Node.js',
    event: 'Youtube',
    location: 'Online/Remote',
    date: ['2017-04-16'],
    icons: ['webinar'],
    media: {
      picture: null,
      slides: null,
      links: null,
      video: 'https://www.youtube.com/watch?v=JrtMT1hbeso',
    },
    description:
      'Online webinar with me (from YLD) and Daniel Van Gils (from Cloud 66) to explore about Node.js and microservice deployment.',
  },

  {
    title: 'Let’s play with HTTP/2!',
    event: 'NodeConf',
    location: 'Barcelona, Spain',
    date: ['2017-04-07'],
    icons: ['star'],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/lets-play-with-http2',
      links: null,
      video: 'https://www.youtube.com/watch?v=ACt8a1CMtYg',
    },
    description: '',
  },

  {
    title: 'Gotcha! I finally understand HTTP/2',
    event: 'SEIUM 2017',
    location: 'Braga, Portugal',
    date: ['2017-01-09', '2017-01-10'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/gotcha-i-finally-understand-http2',
      links: null,
      video: null,
    },
    description: '',
  },

  {
    title: 'My coasters collection',
    event: 'Opensoft Barcamp',
    location: 'Lisbon, Portugal',
    date: ['2017-04-01'],
    icons: [],
    media: {
      picture: null,
      slides: null,
      links: null,
      video: 'https://vimeo.com/225585285',
    },
    description:
      "Non technical talk about my personal coasters collection. I'm sorry but the video is in Portuguese.",
  },

  // 2016

  {
    title: 'React Fiber',
    event: 'Reactivate London Meetup',
    location: 'London, UK',
    date: ['2016-12-06'],
    icons: [],
    media: {
      picture: '../../reactivate-london.jpeg',
      slides:
        'https://github.com/sericaia/fiber-reactivelondon-presentation/tree/master/slides',
      links: 'https://www.meetup.com/Reactivate-London/events/235510096/',
      video: null,
    },
    description:
      'This was the 1st event of Reactivate London Meetup. I talked about React Fiber before everyone started to talk about it ;)',
  },

  {
    title: 'How to be more softly open',
    event: 'Opensoft Barcamp',
    location: 'Lisbon, Portugal',
    date: ['2016-04-30'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/how-to-be-more-softly-open',
      links: null,
      video: 'https://vimeo.com/171113913',
    },
    description:
      "Quick talk about open-source benefits. I'm sorry but the video is in Portuguese.",
  },

  // 2015

  {
    title: 'React: from zero to hero!',
    event: 'Microsoft Webcamp Conference',
    location: 'Lisbon, Portugal',
    date: ['2015-12-02'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://github.com/sericaia/react-msf-presentation',
      links: 'https://github.com/sericaia/react-msf-demos',
      video:
        'https://channel9.msdn.com/Events/DXPortugal/Microsoft-WebCamp-2015/FLSTCK04',
    },
    description:
      'This was a talk with some demos with an introduction to React.js.',
  },

  {
    title: 'REACTivating the web',
    event: 'Require-lx Meetup',
    location: 'Lisbon, Portugal',
    date: ['2015-07-17'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/reactivating-the-web',
      links: 'https://github.com/sericaia/REACTivating-the-web',
      video: null,
    },
    description:
      'This was a talk & workshop. This was given with Sérgio Ramos (@ramitos on Twitter) and was also a test for us with some exercises for a proper/professional client training.',
  },

  {
    title: '10 minutes of HAPIness',
    event: 'Nodeschool Event',
    location: 'Lisbon, Portugal',
    date: ['2015-06-26'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/nodeschool-10-minutes-of-hapiness',
      links: null,
      video: null,
    },
    description: '',
  },

  {
    title: 'what-is-HAPIness',
    event: 'MediterraneaJS Conference',
    location: 'Barcelona, Spain',
    date: ['2015-06-22', '2015-06-23'],
    icons: ['star'],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/nodeschool-10-minutes-of-hapiness',
      links: 'https://github.com/sericaia/mediterraneajs-2015',
      video: 'https://vimeo.com/148351640',
    },
    description:
      'This talk was really cool to understand the request lifecycle in HAPI.js. First time in Barcelona, one of my favorite cities.',
  },

  {
    title: '10 minutes of HAPIness',
    event: 'Opensoft Barcamp',
    location: 'Lisbon, Portugal',
    date: ['2015-04-25'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/10-minutes-of-hapiness',
      links: null,
      video: 'https://vimeo.com/130989956',
    },
    description:
      "Quick talk about HAPI.js basics and relevant modules. I'm sorry but the video is in Portuguese.",
  },

  {
    title: 'HTML & CSS Basics',
    event: 'CoderDojo LX',
    location: 'Lisbon, Portugal',
    date: ['2015-01-24'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/coderdojo-html-css',
      links: null,
      video: null,
    },
    description:
      'This presentation was given some times in CoderDojo LX and aims to help kids to start learning HTML and CSS.',
  },

  // 2014

  {
    title: 'AngularJS',
    event: 'LisbonBigApps',
    location: 'Lisbon, Portugal',
    date: ['2014-07-11'],
    icons: [],
    media: {
      picture: null,
      slides: 'https://slides.com/sericaia/angularjs-lisbonbigapps',
      links: 'https://github.com/sericaia/angular-hapi-lisbonbigapps-tutorial',
      video: null,
    },
    description: '',
  },

  // 2013

  {
    title: 'Part-based Construction of digitized 3D objects',
    event: 'WSCG Conference',
    location: 'Pilsen, Czech Republic',
    date: ['2013-06-26'],
    icons: ['student'],
    media: {
      picture: null,
      slides: null,
      links: 'http://wscg.zcu.cz/wscg2013/program/short/F71-full.pdf',
      video: null,
    },
    description:
      'I did a publication about my master thesis and presented it in an international conference. It was about creating a segmented reconstruction of 3D physical objects using a low-cost camera. This was my first talk ever.',
  },
]
